import React from 'react';
import { Link } from "gatsby";

interface MenuItemProps {
  url: string;
  title: string;
  className?: string;
}

export default function MenuItem(props: MenuItemProps) {
  const { url, title, className = '' } = props;
  const isActivePage = typeof window !== `undefined` ? window.location.pathname.endsWith(url) : false;
  return (
    <Link
      to={url}
      className={`navbar-item menuitem ${className}`}
      aria-current={isActivePage ? 'page' : undefined}
    >{title}</Link>
  );
}
