import React from 'react';
import { StaticImage } from "gatsby-plugin-image";
import './Layout.scss';

export default function Footer() {
  return(
    <footer className="">
      <div className="container">
        <div className="is-fluid is-flex is-flex-direction-row is-justify-content-space-between is-flex-wrap-wrap py-1">
        <div className="is-flex is-flex-direction-column">
          <div>
            <StaticImage
               src="../images/educ.png"
               alt="Educ Logo"
               placeholder="blurred"
               width={100}
               height={100}
             />
          </div>
          <div>
            <span className="footerText is-size-5">©2021</span>
          </div>
        </div>
        <div>
          <h1 className="footerTitle is-size-4">EducAntwerp VZW</h1>
          <span className="footerText is-size-5">
            Terlindenhofstraat 211
            <br/>
            Merksem, 2170
            <br/>
            <a href="mailto:info@educ.network?subject=Hello&body=Hello Educ!...">
            info@educ.network
            </a>
            <br/>
            BE 0722 979 404
            </span>
        </div>
        </div>
      </div>
    </footer>
  )
}
