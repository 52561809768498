import React from 'react';
import './Layout.scss';
import { Helmet } from 'react-helmet'
import NavigationBar from "../components/NavigationBar"
import Footer from "../components/Footer"
import {useTranslation, useI18next} from 'gatsby-plugin-react-i18next';
import useSiteMetadata from './SiteMetadata'

interface LayoutProps {
  children: React.ReactNode;
}

export default function Layout(props: LayoutProps) {
  const { children } = props;

  const { t } = useTranslation();
  const { language } = useI18next();
  const { title, description, author, keywords } = useSiteMetadata();
  return (
    <>
      <Helmet>
        <html lang={language} />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content= {keywords} />
        <meta name="author" content= {author} />
        <link rel="shortcut icon" href="/images/favicon.ico" type="image/x-icon"/>
        <link rel="icon" type="image/png" sizes="32x32" href="/images/favicon-32x32.png" />
      </Helmet>
      <header>
        <NavigationBar />
      </header>
      <main>
        {children}
      </main>
      <Footer />
    </>
  );
}
