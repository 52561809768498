import { useI18next } from 'gatsby-plugin-react-i18next';
import React from 'react';

export function LanguageSwitcher() {
  const { changeLanguage, language } = useI18next();

  return (
    <div className="navbar-item">
      <a className="navLanguage"
         aria-current={ language === "en" ? 'language' : undefined}
         href="#"
         onClick={(e) => {
          e.preventDefault();
            changeLanguage('en');
          }}>
            en
      </a>
      <span className="navLanguage navSlash">/</span>
      <a className="navLanguage"
          aria-current={language === "nl" ? 'language' : undefined}
          href="#"
          onClick={(e) => {
          e.preventDefault();
            changeLanguage('nl');
          }}>
            nl
      </a>
    </div>
  );

}
