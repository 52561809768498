import React, { useState } from 'react';
import styles from './NavigationBar.module.scss';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { StaticImage } from "gatsby-plugin-image";
import MenuItem from "../components/MenuItem"
import { LanguageSwitcher } from './LanguageSwitcher';


export default function NavigationBar(){
  const { t } = useTranslation();

  const [active, setActive] = useState(false);
  const navBarActiveClass = active ? 'is-active' : '';

  const toggleHamburger = () => setActive(!active);

  return(
    <nav className="navbar navbar-background-color"
         role="navigation"
         aria-label="main navigation">
      <div className="container">
        <div className="navbar-brand">
          <a className=""
             href="/">
             <StaticImage
                src="../images/educ.png"
                alt="Educ Logo"
                placeholder="blurred"
                width={90}
                height={90}
              />
          </a>
          <a className="navbar-burger navbar-burger-color"
             role="button"
             onClick={toggleHamburger}
             aria-label="menu"
             aria-expanded="false">
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
        <div className={`navbar-menu ${navBarActiveClass}`}>
          <div className="navbar-end">
            <MenuItem url='/' title={t('home')} />
            <MenuItem url='/about' title={t('about')} />
            <MenuItem url='/projects' title={t('projects')} />
            <LanguageSwitcher />
          </div>
        </div>
      </div>
    </nav>
  )
}
